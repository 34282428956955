









































import DraftElement from '@/builder/sections/mixins/DraftElement'
import mixins from 'vue-typed-mixins'

export default mixins(DraftElement).extend({
  name: 'AppFooterSignature',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    date: new Date().getFullYear()
  }),
  computed: {
    socialMedia (): Record<string, any> {
      return [
        { icon: '$fb', link: this.data.configuration.wb_facebook__url__ },
        { icon: '$instagram', link: this.data.configuration.wb_instagram__url__ },
        { icon: '$yt', link: this.data.configuration.wb_youtube__url__ },
        { icon: '$twitter', link: this.data.configuration.wb_twitter__url__ }
      ]
    }
  }
})
